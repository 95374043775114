import { Injectable } from '@angular/core';
import { HttpHelperService } from './base/http-helper.services';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private httpHelperService: HttpHelperService) {}

  orderId: number = -1;

  sendEvent(eventName: string, event?: { [key: string]: any }) {
    this.httpHelperService
      .SendPostRequest('api/Users/CreateFirebaseEventLog', {
        orderId: this.orderId,
        eventName: eventName,
        body: event,
      })
      .subscribe();
  }
}
