<div class="confirmation-call-widget">
  <div class="row">
    <div class="col-12 d-flex flex-column align-items-center">
      <img
        loading="lazy"
        src="../../../assets/images/confirmation-call.svg"
        class="img-fluid confirmation-call-image"
        alt="confirmation call"
      />

      <div class="title">
        {{ "confirmation-call" | translate }}
      </div>

      <div class="subtitle">
        {{ "phone-call-will-be-postponed" | translate }}
        <span class="phone">{{ phoneNumber }}</span>
        {{ "to-confirm-the-process" | translate }}
      </div>

      @if(isErrorViewed) {
      <p class="error">{{ errorMessage }}</p>
      }

      <button
        class="btn btn-primary confirm-button"
        (click)="confirm()"
        [disabled]="isRequestPending"
      >
        {{ "confirm" | translate }}
        <img
          src="../../../assets/images/login/side-arrow.svg"
          class="img-fluid ms-2"
          alt="side-arrow"
        />
      </button>
    </div>
  </div>
</div>
