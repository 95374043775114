import { Component, DestroyRef, inject } from '@angular/core';
import { PaymentProcessService } from 'src/app/services/payment-process.service';
import { CallConfirmationService } from './service/call-confirmation.service';
import { ChangeLanguageService } from 'src/app/services/helper/change-language.service';
import { CheckoutService } from 'src/app/services/checkout.service';
import { OrderDetailsRes } from 'src/app/models/getOrderDetails';
import { take } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PaymentData } from 'src/app/enums/PaymentType';

@Component({
  selector: 'app-confirm-payment-va-tel',
  templateUrl: './confirm-payment-va-tel.component.html',
  styleUrls: ['./confirm-payment-va-tel.component.scss'],
})
export class ConfirmPaymentVaTelComponent {
  isRequestPending: boolean = false;
  phoneNumber: string = '';
  isErrorViewed: boolean = false;
  errorMessage: string = '';

  destroyRef = inject(DestroyRef);

  constructor(
    private readonly paymentProcessService: PaymentProcessService,
    private readonly callConfirmationService: CallConfirmationService,
    private readonly changeLanguageService: ChangeLanguageService,
    private readonly checkoutService: CheckoutService
  ) {}

  ngOnInit() {
    this.phoneNumber = localStorage.getItem('mobile') ?? '';
    this.subscribeOrderDetails();
    this.subscribePaymentData();
  }

  subscribeOrderDetails() {
    this.checkoutService.orderDetails
      .pipe(take(1))
      .subscribe((orderDetailsRes: OrderDetailsRes) => {
        this.initiateConfirmationCall(orderDetailsRes?.data?.orderId);
      });
  }

  subscribePaymentData() {
    this.paymentProcessService.paymentData
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((paymentData: PaymentData) => {
        if (paymentData.callConfirmationError) {
          this.isErrorViewed = true;
          this.errorMessage = paymentData.callConfirmationError;
        }
      });
  }

  initiateConfirmationCall(order_id: number) {
    this.isErrorViewed = false;

    this.callConfirmationService
      .initiateConfirmationCall({
        orderId: order_id,
        language: this.changeLanguageService.getLanguage(),
      })
      .subscribe({
        next: () => {
          this.isRequestPending = false;
        },
        error: (error) => {
          this.isErrorViewed = true;
          this.errorMessage = error.error.message;
          this.isRequestPending = false;
        },
      });
  }

  confirm() {
    this.paymentProcessService.startPaymentOperation();
  }
}
