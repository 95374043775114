import { Component, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { OnboardingStep } from 'src/app/models/signUp';
import { PhoneVerificationFailureService } from 'src/app/pages/sign-up/services/phone-verification-failure.service';
import { SignUpService } from 'src/app/pages/sign-up/services/sign-up.service';

@Component({
  selector: 'app-sign-up-change-phone-number',
  standalone: false,
  templateUrl: './change-phone-number.component.html',
  styleUrl: './change-phone-number.component.scss',
})
export class ChangePhoneNumberComponent {
  @Output() openNextStep = new Subject<keyof typeof OnboardingStep>();

  isModalOpened$ =
    this.phoneVerificationFailureService.isChangeMobileModalOpened$;

  phoneForm: FormGroup;
  msgError: string = '';
  isRequestPending: boolean = false;

  constructor(
    private readonly signUpService: SignUpService,
    private readonly phoneVerificationFailureService: PhoneVerificationFailureService
  ) {
    this.phoneForm = new FormGroup({
      mobile: new FormControl('', [
        Validators.required,
        Validators.pattern('^5[0-9]{8}$'),
      ]),
    });
  }

  get mobileControl() {
    return this.phoneForm.controls['mobile'];
  }

  checkValidation() {
    const mobileControl = this.phoneForm.get('mobile')!;

    if (mobileControl.hasError('incorrectMobile')) {
      mobileControl.setErrors(null);
    }
  }

  changePhoneNumber() {
    this.isRequestPending = true;
    this.signUpService
      .ChangeMobile({
        mobile: this.mobileControl.value,
        mobileCountryCode: '+966',
      })
      .subscribe({
        next: (res) => {
          this.isRequestPending = false;

          if (res.succeeded) {
            localStorage.setItem('mobile', this.mobileControl.value);

            this.phoneForm.reset();

            const onboardingStep = 'otp';
            this.signUpService.setOnboardingStep(
              OnboardingStep[onboardingStep]
            );

            this.signUpService.setMobileAndIdGuid(
              this.mobileControl.value,
              res.data.idGuid
            );

            this.phoneVerificationFailureService.closeChangeMobileModal();
            this.handleOpenNextStep(onboardingStep);
          } else if (res.code === 1003) {
            this.phoneVerificationFailureService.closeChangeMobileModal();
            this.phoneVerificationFailureService.openReachedTriesLimitsModal();
          } else {
            this.msgError = res.message!;
            this.mobileControl.setErrors({ incorrectMobile: true });
          }
        },
        error: (error) => {
          this.isRequestPending = false;
          this.msgError = error.error?.detail;
          this.mobileControl.setErrors({ incorrectMobile: true });
        },
      });
  }

  handleOpenNextStep(nextStep: keyof typeof OnboardingStep) {
    this.openNextStep.next(nextStep);
  }
}
