<button
  type="button"
  class="btn btn-text cancel-order-button"
  data-bs-toggle="modal"
  data-bs-target="#cancelOrderModal"
  [ngClass]="{ 'login-page-icon': isLogin }"
  (click)="openModal()"
>
  <img
    [src]="
      isLogin
        ? '/assets/images/cancel-order-modal/tag-cross-home.svg'
        : '/assets/images/cancel-order-modal/tag-cross.svg'
    "
    class="img-fluid cancel-order"
    alt="cancel order"
  />
</button>

<div
  class="modal fade cancel-order-modal"
  id="cancelOrderModal"
  tabindex="-1"
  aria-labelledby="cancelOrderModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" [class.step-2]="step === 2">
    <div class="modal-content">
      <div
        class="modal-body"
        [class.step-2]="step === 2"
        [class.step-3]="step === 3"
      >
        @if (step === 1){
        <img
          src="/assets/images/cancel-order-modal/cart.png"
          class="img-fluid cancel-order"
          alt="incomplete order warning"
        />

        <p class="title">
          {{ "Uh-oh you are leaving your cart behind" | translate }}
        </p>
        <p class="subtitle">
          {{ "are-you-sure-to-go-back" | translate }}
        </p>

        <button
          type="button"
          class="btn btn-primary step-1-button col-12"
          (click)="openStep2(2)"
        >
          {{ "Continue Ordering" | translate }}
        </button>

        <button
          type="button"
          class="btn btn-primary step-1-button col-12"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="declineCancelOrder()"
        >
          {{ "Cancel Order" | translate }}
        </button>
        } @if(step === 2){

        <img
          src="/assets/images/cancel-order-modal/empty-box.png"
          class="img-fluid cancel-order"
          alt="incomplete order warning"
        />

        <p class="title title-step-2">
          {{ "we-noticed-you-didnt-complete-order" | translate }}
        </p>
        <p class="subtitle subtitle-step-2">
          {{ "help-us-to-improve-experience" | translate }}
        </p>
        <p class="subtitle subtitle-2-step-2">
          {{ "what-stopped-completing" | translate }}
        </p>

        <div class="options">
          <form>
            <div
              class="check-box-container"
              *ngFor="let reason of feedbackReasons; let i = index"
            >
              <label [for]="'acceptTerms' + reason.surveyQuestionId">
                <input
                  [id]="'acceptTerms' + reason.surveyQuestionId"
                  type="checkbox"
                  [checked]="selectedReasons.includes(reason.surveyQuestionId)"
                  (change)="onChangeReason(reason.surveyQuestionId)"
                />
                <span class="checkmark"></span>

                <p class="survey-question">
                  {{ reason.surveyQuestion }}
                </p>
              </label>
            </div>
          </form>

          <form *ngIf="reasonsForm" [formGroup]="reasonsForm">
            <div *ngFor="let question of textFeedbackReasons">
              <p class="text-question-title subtitle-2-step-2">
                {{ question.surveyQuestion }}
              </p>

              <input
                class="form-control"
                [id]="question.surveyQuestionId.toString()"
                [formControlName]="question.surveyQuestionId.toString()"
              />
            </div>
          </form>
        </div>

        <button
          type="button"
          class="btn btn-primary continue-ordering-btn col-12"
          (click)="submitReasonsForm()"
          [disabled]="loading"
        >
          {{ "Submit" | translate }}
        </button>

        <button
          type="button"
          class="btn btn-primary skip-ordering-btn col-12"
          (click)="moveToStep3()"
          [disabled]="loading"
        >
          {{ "skip" | translate }}
        </button>
        } @if(step === 3){
        <img
          src="/assets/images/cancel-order-modal/compass.png"
          class="img-fluid cancel-order"
          alt="incomplete order warning"
        />

        <p class="title title-step-3">
          {{ "thank-you-for-feedback" | translate }}
        </p>

        <button
          type="button"
          class="btn btn-primary continue-ordering-btn col-12"
          (click)="directCancelOrder()"
        >
          {{ "back-to-home-button" | translate }}
        </button>
        }
      </div>
    </div>
  </div>
</div>
