import { Component, computed, Input, OnInit } from '@angular/core';
import { OrderDetailsRes } from 'src/app/models/getOrderDetails';
import { HelperService } from 'src/app/services/Helper.service';
import {
  ErrorPopupService,
  ErrorPopupType,
} from 'src/app/services/error-popup.service';
import { PaymentProcessService } from 'src/app/services/payment-process.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
  @Input({ required: true }) orderDetailsRes?: OrderDetailsRes;
  title?: string = '';
  message?: string = '';
  errorType?: ErrorPopupType = undefined;

  isModalOpened: boolean = false;
  ErrorPopupType = ErrorPopupType;

  constructor(
    private helperService: HelperService,
    private errorPopupService: ErrorPopupService,
    private paymentProcessService: PaymentProcessService
  ) {}

  imageLink = computed(() => {
    return this.errorType === ErrorPopupType.IQAMA_EXPIRATION_WARNING
      ? '/assets/images/cancel-order-modal/incomplete-order-warning.svg'
      : '/assets/images/error-modal.svg';
  });

  ngOnInit(): void {
    this.subscribeToErrorService();
  }

  subscribeToErrorService() {
    this.errorPopupService.errorData.subscribe((errorData) => {
      this.isModalOpened = errorData.isOpened;
      this.title = errorData.title;
      this.message = errorData.message;
      this.errorType = errorData.type;

      if (errorData.isOpened && errorData.type === ErrorPopupType.ERROR) {
        this.onErrorHandler();
      }
    });
  }

  handleErrorCallback() {
    window?.parent?.postMessage({ callback: 'errorCallback', data: null }, '*');
  }

  onErrorHandler() {
    this.handleErrorCallback();
    this.helperService.HandleErrorPaymentResult(this.orderDetailsRes);
  }

  tryAgain() {
    if (this.errorType === ErrorPopupType.IQAMA_EXPIRATION_WARNING) {
      this.paymentProcessService.startPaymentOperation();

      this.errorPopupService.errorData.next({
        isOpened: false,
        title: '',
        message: '',
        type: ErrorPopupType.ERROR,
      });
    } else if (this.errorType === ErrorPopupType.ERROR) {
      this.openFailiureLink();
    }
  }

  openFailiureLink() {
    this.handleErrorCallback();

    if (this.orderDetailsRes?.data?.merchantUrls?.failure) {
      this.helperService.DirectRedirectToLink(
        this.orderDetailsRes?.data?.merchantUrls?.failure
      );
    }
  }
}
