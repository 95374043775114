import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum ErrorPopupType {
  ERROR = 'error',
  IQAMA_EXPIRATION_WARNING = 'iqama expiration warning',
}

@Injectable({
  providedIn: 'root',
})
export class ErrorPopupService {
  errorData = new BehaviorSubject({
    isOpened: false,
    title: '',
    message: '',
    type: ErrorPopupType.ERROR,
  });

  constructor() {}

  openModal(
    title: string,
    message: string,
    type: ErrorPopupType = ErrorPopupType.ERROR
  ) {
    this.errorData.next({
      isOpened: true,
      title: title,
      message: message,
      type,
    });
  }
}
