import { Component, Injectable } from '@angular/core';
import { DateOfBirthService } from './service/date-of-birth.service';
import { statusCode } from 'src/app/enums/PaymentType';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { PaymentProcessService } from 'src/app/services/payment-process.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ANALYTICS_EVENTS } from 'src/app/constants';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year
      : '';
  }
}

@Component({
  selector: 'app-confirm-payment-using-birth-date',
  templateUrl: './confirm-payment-using-birth-date.component.html',
  styleUrls: ['./confirm-payment-using-birth-date.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class ConfirmPaymentUsingBirthDateComponent {
  gregorianDate: NgbDateStruct | undefined;
  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;

  selectedDate: any = '';
  statusCode = statusCode;
  showDateOfBirthError: boolean = false;
  dateOfBirthErrorMsg: string = '';
  model: any = {};
  dateType: string = 'gregorian';

  constructor(
    private dateOfBirth: DateOfBirthService,
    private paymentProcessService: PaymentProcessService,
    private readonly analyticsService: AnalyticsService
  ) {
    const currentYear = new Date().getFullYear();
    this.minDate = { year: currentYear - 100, month: 1, day: 1 };
    this.maxDate = { year: currentYear - 18, month: 1, day: 1 };
  }

  onDateTypeChange(type: string) {
    const eventMessage =
      type === 'hijri'
        ? ANALYTICS_EVENTS.BIRTH_DATE_CONFIRMATION.HIJRI_DOB
        : ANALYTICS_EVENTS.BIRTH_DATE_CONFIRMATION.GREGORIAN_DOB;

    this.analyticsService.sendEvent(eventMessage);
  }

  onInputChange(e: any) {
    this.analyticsService.sendEvent(
      ANALYTICS_EVENTS.BIRTH_DATE_CONFIRMATION.ENTER_DOB
    );

    this.selectedDate = e;
  }

  confirm() {
    this.analyticsService.sendEvent(
      ANALYTICS_EVENTS.BIRTH_DATE_CONFIRMATION.CONFIRM_DOB
    );

    this.showDateOfBirthError = false;
    this.dateOfBirthErrorMsg = '';
    this.dateOfBirth.setDateOfBirth(this.selectedDate).subscribe({
      next: (res: any) => {
        if (res.data.succeeded) {
          this.paymentProcessService.startPaymentOperation();
        } else {
          this.showDateOfBirthError = true;
          this.dateOfBirthErrorMsg = res.data.errors[0];
        }
      },
      error: (error) => {
        this.showDateOfBirthError = true;
        this.dateOfBirthErrorMsg = error.error.message || error.error.detail;

        if (error?.error?.errors) {
          const keys = Object.keys(error?.error?.errors);
          this.dateOfBirthErrorMsg = error?.error?.errors[keys[0]];
        }
      },
    });
  }
}
