<div class="otp-confirmation-popup">
  <div class="widget">
    <div class="logo">
      <img
        src="../../../assets/images/otpverification.svg"
        class="img-fluid"
        alt="otp verification"
      />
    </div>

    <p class="title">{{ "otp-confirmation" | translate }}</p>
    <p class="subtitle" *ngIf="!otpErrorFlag">
      {{ "please-enter-otp-code" | translate }}
      <span class="phone">{{ phoneNumber }}</span>
    </p>

    <form [formGroup]="otpForm" *ngIf="!otpErrorFlag">
      <div class="form-row align-items-center">
        <div class="col-auto col-10 offset-1">
          <div class="input-group otp-group">
            <input
              type="tel"
              id="otp1"
              maxlength="1"
              placeholder=""
              formControlName="otp1"
              (keyup)="moveFocus($event, 'otp2', '', 'otp1')"
              [ngClass]="{
                'red-border': otpForm.hasError('incorrectOTP')
              }"
            />

            <input
              type="tel"
              id="otp2"
              maxlength="1"
              placeholder=""
              formControlName="otp2"
              (keyup)="moveFocus($event, 'otp3', 'otp1', 'otp2')"
              [ngClass]="{
                'red-border': otpForm.hasError('incorrectOTP')
              }"
            />

            <input
              type="tel"
              id="otp3"
              maxlength="1"
              placeholder=""
              formControlName="otp3"
              (keyup)="moveFocus($event, 'otp4', 'otp2', 'otp3')"
              [ngClass]="{
                'red-border': otpForm.hasError('incorrectOTP')
              }"
            />

            <input
              type="tel"
              id="otp4"
              maxlength="1"
              placeholder=""
              formControlName="otp4"
              (keyup)="moveFocus($event, '', 'otp3', 'otp4')"
              [ngClass]="{
                'red-border': otpForm.hasError('incorrectOTP')
              }"
            />
          </div>

          <div class="resend-area text-center">
            <span *ngIf="showSubmitMsg" class="expire-messgae">
              {{ "Did-not-receive-the-code" | translate }}
              <span>
                <button
                  class="resend-button"
                  type="button"
                  (click)="this.resendOTP()"
                  [disabled]="showVerificationError || countdown > 0"
                >
                  {{ "Resend-the-code" | translate }}
                </button>
              </span>
              {{ "in" | translate }}
              {{ minutes | number : "2.0-0" }} :
              {{ countdown % 60 | number : "2.0" }}
            </span>

            <span
              *ngIf="showVerificationError && !otpErrorFlag"
              class="mt-3 verification-error"
            >
              {{ invalidOTPCode }}
              <span>
                <button
                  type="button"
                  class="resend-button"
                  (click)="this.resendOTP()"
                >
                  {{ "Resend-the-code" | translate }}
                </button>
              </span>
            </span>
          </div>
        </div>

        <div class="col-auto col-12">
          <button
            class="btn btn-primary approve-button"
            (click)="checkOTP()"
            [disabled]="!enableSubmitBtn || this.otpForm.invalid"
          >
            {{ "confirm" | translate }}
            <img
              src="../../../assets/images/login/side-arrow.svg"
              class="img-fluid ms-2"
              alt="arrow"
            />
          </button>
        </div>
      </div>
    </form>

    <p class="verification-error" *ngIf="otpErrorFlag">
      {{ otpError }}
    </p>
  </div>
</div>
