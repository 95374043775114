<div
  *ngIf="isModalOpened$ | async"
  class="modal fade nationalIdConfirmationModal show"
  id="nationalIdConfirmationModal"
  aria-labelledby="nationalIdConfirmationModalLabel"
  tabindex="-1"
  style="display: block"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <img
          src="/assets/images/sign-up/confirmation-call.png"
          class="img-fluid error-image"
          alt="Madfu"
        />

        <form
          [formGroup]="phoneForm"
          (ngSubmit)="changePhoneNumber()"
          (keydown.enter)="changePhoneNumber()"
        >
          <div class="text-container">
            <p class="title mb-1">
              {{ "please-enter-valid-phone" | translate }}
            </p>

            <div class="phone-input-widget">
              <div class="country-code">
                <img
                  src="/assets/images/sa-flag.svg"
                  class="img-fluid sa-flag"
                  alt="SA flag"
                />

                +966
              </div>

              <div class="phone-input-container">
                <input
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  (focusout)="checkValidation()"
                  minlength="9"
                  maxlength="9"
                  type="tel"
                  class="form-control"
                  appLeadingZero
                  id="UserMobile"
                  placeholder="5xx xxx xxx"
                  formControlName="mobile"
                  [ngClass]="{
                    'red-border': mobileControl.hasError('pattern')
                  }"
                />

                <div
                  *ngIf="mobileControl.hasError('pattern')"
                  class="col-12 mt-1 error-message"
                >
                  {{ "Mobile-number-must-consist-of-nine-digits" | translate }}
                </div>

                <div
                  *ngIf="mobileControl?.hasError('incorrectMobile')"
                  class="col-12 mt-1 error-message"
                >
                  {{ msgError }}
                </div>
              </div>
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-primary confirm-btn"
            [disabled]="isRequestPending || phoneForm.invalid"
          >
            {{ "signUpPhoneChangeContinue" | translate }}
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isModalOpened$ | async" class="modal-backdrop fade show"></div>
