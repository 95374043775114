import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, catchError, finalize, throwError } from 'rxjs';
import { environment } from '../environment/environment';
import { Injectable } from '@angular/core';
import { LoaderService } from '../services/Loader.Service';
import { ApplicationLanguages } from '../enums/lang';
import { ErrorPopupService } from '../services/error-popup.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  /**
   *
   */
  applicationLang = ApplicationLanguages;
  constructor(
    private loaderService: LoaderService,
    private errorPopupService: ErrorPopupService,
    private translate: TranslateService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
    req = req.clone({
      headers: req.headers.set(
        'Accept-Language',
        localStorage.getItem('lang') == this.applicationLang.Ar
          ? this.applicationLang.Ar
          : this.applicationLang.En
      ),
    });
    req = req.clone({
      headers: req.headers.set('Content-type', 'application/json'),
    });
    req = req.clone({ headers: req.headers.set('apikey', environment.apikey) });
    req = req.clone({
      headers: req.headers.set('appcode', environment.appcode),
    });
    req = req.clone({ headers: req.headers.set('withCredentials', 'true') });
    req = req.clone({ headers: req.headers.set('X-Frame-Options', 'DENY') });
    req = req.clone({ headers: req.headers.set('platformTypeId', '1') });
    req = req.clone({
      headers: req.headers.set(
        'Authorization',
        btoa(environment.username + ':' + environment.password)
      ),
    });

    const storedToken = localStorage.getItem('token');

    if (
      storedToken != null &&
      storedToken != '' &&
      storedToken != undefined &&
      storedToken != 'undefined'
    ) {
      req = req.clone({ headers: req.headers.set('token', storedToken) });
    }

    this.loaderService.show();

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !req.url.includes('api/Users/SignIn')) {
          this.errorPopupService.openModal(
            this.translate.instant('session-expired'),
            ''
          );

          return throwError(
            () => new Error(this.translate.instant('session-expired'))
          );
        }

        return throwError(() => error);
      }),
      finalize(() => {
        this.loaderService.hide();
      })
    );
  }
}
