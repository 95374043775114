import { Injectable } from '@angular/core';
import { HttpHelperService } from 'src/app/services/base/http-helper.services';

@Injectable({
  providedIn: 'root',
})
export class CallConfirmationService {
  constructor(private httpHelperService: HttpHelperService) {}

  initiateConfirmationCall(data: any) {
    return this.httpHelperService.SendPostRequest(
      'api/users/PaymentCallConfirmation',
      data
    );
  }
}
